<template>
  <main class="register-consultant admin-page bg-cream px-12 ml-auto">
    <div class="container min-h-screen flex flex-col pt-12 pb-20">
      <header class="admin-page-header mb-10">
        <h1 class="text-3xl text-blue">Consultoras / {{ isEdit ? 'Editar' : 'Novo' }} cadastro</h1>
      </header>

      <Loader :isloading="loading" />

      <nivea-form v-if="!loading" :submit="submit">
        <div class="form-block">
          <field name="name" label="Nome" rules="required" v-slot="{ label }">
            <v-text-field v-model="form.name" :label="label" hide-details="auto" />
          </field>

          <field name="email" label="E-mail" rules="required|email" v-slot="{ label }">
            <v-text-field type="email" v-model="form.email" :label="label" hide-details="auto" />
          </field>

          <phone v-model="form.phone" />
          <span class="text-xs text-blue font-light">Apenas números</span>

          <file
            @remove="removeImage"
            @set="setImage"
            class="mt-10 block"
            :value="form.avatar"
            title="Avatar"
            instructions="Selecione uma foto (PNG) com até 240x240 pixels"
          />

          <field name="bio" label="Mini Bio" rules="required" class="mt-12 flex flex-col" v-slot="{ label }">
            <v-textarea
              v-model="form.welcome_message"
              :label="label"
              class="consultant-bio resize-none w-full py-5 text-lg focus:outline-none text-blue" rows="5"
              :hint="form.welcome_message.length + '/150'"
              persistent-hint
            />
          </field>
        </div>

        <h2 class="text-2xl text-blue font-bold mt-14">Configurações da agenda</h2>

        <h3 class="text-lg text-blue font-normal mt-10 mb-5">Duração das consultas</h3>
        <minutes v-model="form.duration" name="duration" :options="durations" />

        <h3 class="text-lg text-blue font-normal mt-10 mb-5">Intervalo entre consultas</h3>
        <minutes v-model="form.gap" name="gap" :options="gaps" />

        <h3 class="text-lg text-blue font-normal mt-10 mb-5">Disponibilidade</h3>
        <availability v-model="form.availability" :duration="form.duration" :gap="form.gap" />

        <Button type="submit" class="mt-12">{{ isEdit ? 'Atualizar' : 'Cadastrar' }}</Button>
      </nivea-form>
    </div>
  </main>
</template>

<script>
import { mapState } from 'vuex';
import api, { buildFormData } from '@/utils/api.js';

import File from "@/components/fields/File";
import Field from "@/components/fields/Field";
import Phone from "@/components/fields/Phone";
import Button from "@/components/Button";
import Loader from "@/components/Loader";
import NiveaForm from '@/components/NiveaForm';

import Minutes from '@/components/fields/Minutes';
import Availability from '@/components/fields/Availability';

export default {
  name: "RegisterConsultant",
  components: {
    Availability,
    Minutes,
    File,
    Field,
    Phone,
    Button,
    Loader,
    NiveaForm,
  },
  computed: {
    ...mapState('consultants', ['form']),
    isEdit() {
      return this.$route.params.action === 'edit';
    }
  },
  data() {
    return {
      loading: false,
      durations: [5, 10, 15, 20],
      gaps: [0, 5, 10, 15, 30, 45, 60],
    }
  },
  mounted() {
    if (this.isEdit) {
      this.fetchUser();
    } else {
      this.$store.dispatch('consultants/reset');
    }
  },
  methods: {
    removeImage() {
      this.file = null;
      this.form.avatar = '';
    },
    setImage(event) {
      this.file = event.target.files[0];
    },

    async submit() {
      const params = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };

      const formData = new FormData();
      buildFormData(formData, this.form);

      if (this.file) {
        formData.append('avatar', this.file);
      } else {
        formData.delete('avatar');
      }

      if (this.isEdit) {
        formData.append('_method', 'PUT');
        await api.post(`consultants/${this.$route.params.id}`, formData, params);
        this.$noty.success('Consultora atualizada com sucesso.');
      } else {
        await api.post('consultants', formData, params);
        this.$noty.success('Consultora cadastrada com sucesso.');
      }

      this.$router.push({ name: 'Consultants' })
    },

    async fetchUser() {
      this.loading = true;
      const response = await api.get(`/consultants/${this.$route.params.id}`);
      this.$store.dispatch('consultants/set', response.data);
      this.loading = false;
    }
  },
};
</script>

<style lang="scss">
.register-consultant {
  .nivea-form {
    width: 100%;
  }

  .form-block {
    max-width: 480px;
  }

  .field + .field {
    @apply mt-12;
  }
}

.consultant-bio {
  .v-messages__message {
    text-align: right;
  }
}
</style>
