<template>
  <div class="schedule-modal fixed top-0 left-0 flex items-center justify-center w-full h-full opacity-0">
    <div class="grid grid-cols-3 schedule-modal__card bg-white opacity-0 rounded-lg overflow-hidden">
      <div class="col-span-2 p-10">
        <h3 class="text-lg text-blue font-normal mb-5">Defina a disponibilidade</h3>

        <div class="space-y-4">
          <div v-for="(period, index) in formattedPeriods" :key="index" class="relative flex gap-5">
            <period v-model="period.value" />
            <button @click="removePeriod(index)" class="schedule-modal__period-btn-remove"></button>
          </div>
        </div>

        <div class="text-blue-100 mt-3 block">
          <span class="cursor-pointer" @click="addPeriod">+ Novo intervalo</span>
        </div>

        <div class="schedule-modal__actions flex flex-col items-streach justify-between mt-5">
          <Button @click="save" :disabled="!isValid">Aplicar a todas {{ weekDayName }}</Button>
          <Button ghost @click="closeModal" class="border-0 mt-3">Cancelar</Button>
        </div>
      </div>
      <div class="bg-red-100 p-10">
        <b class="text-lg font-normal block mb-5">Agenda</b>
        <p v-if="!formattedPeriods.length">
          Você não definiu os horários para este dia da semana.
        </p>

        <div class="pl-4" v-else>
          <ul class="list-disc text-sm" v-for="(slot, index) in slots" :key="index">
            <li v-for="time in slot" :key="time">
              {{ time }}
            </li>
          </ul>
        </div>
      </div>
    </div>

    <span
      class="schedule-modal-layer fixed top-0 left-0 w-full h-full bg-black opacity-0 cursor-pointer"
      @click="closeModal"
    />
  </div>
</template>

<script>
import { getTime, createTimeSlot } from '@/utils/schedule.js';
import Button from '@/components/Button';
import Period from '@/components/fields/Period';

export default {
  name: 'ScheduleModal',
  components: {
    Period,
    Button,
  },
  props: ['periods', 'weekDay', 'duration', 'gap'],
  watch: {
    periods: {
      deep: true,
      handler() {
        this.formattedPeriods = this.formatPeriods();
      }
    }
  },
  computed: {
    isValid() {
      const invalid = this.formattedPeriods.some((p) => {
        const values = p.value.split('-');
        return values.length < 2 || p.value.includes('mm') || p.value.includes('HH') || p.value.endsWith('-');
      });

      return !invalid;
    },
    weekDayName() {
      const mapWeekNames = {
        'monday': 'Segundas',
        'tuesday': 'Terças',
        'wednesday': 'Quartas',
        'thursday': 'Quintas',
        'friday': 'Sextas',
        'saturday': 'Sabados',
        'sunday': 'Domingos',
      }

      return mapWeekNames[this.weekDay];
    },
    slots() {
      return this.formattedPeriods
        .map(p => p.value)
        .map((p) => {
          return getTime(p);
        })
        .filter(p => p.start && p.end)
        .map(p => {
          return createTimeSlot(p.start, p.end, this.duration, this.gap);
        });
    }
  },
  data() {
    return {
      formattedPeriods: []
    }
  },
  methods: {
    formatPeriods() {
      return this.periods.map((period) => {
        return { value: period };
      });
    },
    addPeriod() {
      this.formattedPeriods.push({
        value: ''
      });
    },
    removePeriod(index) {
      this.formattedPeriods.splice(index, 1);
    },
    save() {
      const newPeriods = this.formattedPeriods.map((p) => p.value);
      this.$emit('save', newPeriods);
      this.formattedPeriods = [{ value: '' }];
      this.closeModal();
    },
    closeModal() {
      this.$store.dispatch('consultants/closeModal');
    },
  },
};
</script>

<style lang='scss'>
.has-schedule-modal {
  .schedule-modal {
    opacity: 1;
    pointer-events: auto;
  }

  .schedule-modal-layer {
    @apply opacity-30;
  }

  .schedule-modal__card {
    opacity: 1;
    transform: none;
  }
}

.schedule-modal {
  z-index: 100;
  bottom: 0;
  pointer-events: none;
  transition: opacity 0.3s, transform .3s;
}

.schedule-modal-layer {
  z-index: -1;
}

.schedule-modal__card {
  width: 760px;
  height: 523px;
  overflow-y: scroll;
  transform: scale(0.90);
  transition: transform .3s, opacity 0.3s;

  .field + .field {
    margin-top: 0 !important;
  }
}

.schedule-modal__period-btn-remove {
  @apply absolute right-0 inset-y-1/2 transform -translate-y-1/2 w-5 h-5 cursor-pointer;

  background-image: url("data:image/svg+xml,%3Csvg width='16' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 7v10H4V7h8zm-1.5-6h-5l-1 1H1v2h14V2h-3.5l-1-1zM14 5H2v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V5z' fill='%2300136F' stroke='%23fff' stroke-width='.5'/%3E%3C/svg%3E");
}

// Time Picker

.vue__time-picker input.display-time {
  border: none;
  border-bottom: 1px solid rgba(#00136F, 0.24);
  padding: 0;

  &:focus {
    outline: none;
  }
}
</style>
