<template>
  <div class="relative flex space-x-5">
    <ValidationProvider name="startPeriod" tag="div">
      <label class="block uppercase text-sm mb-2 text-blue text-opacity-50">A partir de</label>
      <vue-timepicker
        v-model="start"
        format="HH:mm"
        :minute-interval="10"
        @input="onInput"
        @change="updateRange"
        close-on-complete
        auto-scroll
        placeholder="09:00"
      />
    </ValidationProvider>

    <ValidationProvider name="endPeriod" tag="div">
      <label class="block uppercase text-sm mb-2 text-blue text-opacity-50">Até</label>
      <vue-timepicker
        v-model="end"
        format="HH:mm"
        :minute-interval="10"
        :hour-range="hourRange"
        @input="onInput"
        close-on-complete
        auto-scroll
        placeholder="12:00"
      />
    </ValidationProvider>
  </div>
</template>

<script>
import VueTimepicker from 'vue2-timepicker';

export default {
  name: 'Period',
  components: {
    VueTimepicker
  },
  props: ['value'],
  data() {
    return {
      start: '',
      end: '',
      hourRange: [[0, 23]]
    }
  },
  mounted() {
    const [start, end] = this.value.split('-');
    this.start = start || '';
    this.end = end || '';
  },
  methods: {
    updateRange(event) {
      const start = parseInt(event.data.H, 10);
      this.hourRange = [[start, 23]];
    },

    onInput() {
      this.$emit('input', `${this.start}-${this.end}`);
    }
  }
}
</script>

<style lang="scss">
.vue__time-picker .dropdown ul li:not([disabled]).active,
.vue__time-picker .dropdown ul li:not([disabled]).active:focus,
.vue__time-picker .dropdown ul li:not([disabled]).active:hover {
  @apply bg-blue;
}
</style>
