<template>
  <div>
    <ul class="flex max-h-full min-h-36">
      <li
        v-for="(day, index) in days"
        :key="index"
        @click="openSchedule(day)"
        class="availability-item relative w-32 p-3 bg-white rounded-md mr-5 text-sm text-blue"
      >
        <b class="block opacity-60">{{ day.name }}</b>

        <p v-for="(period, index) in day.periods.slice(0, 2)" :key="index" class="text-blue border rounded border-blue-200 p-1.5 text-center mt-2">
          {{ period.start }} - {{ period.end }}
        </p>

        <span v-if="day.periods.length >= 3" class="absolute flex justify-center items-center inset-x-1/2 bottom-0 h-3 w-8 bg-blue-300 rounded-lg transform -translate-x-1/2  -translate-y-1/2">
          <svg width="14" height="3" viewBox="0 0 14 3" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.82205 0.0199999C1.21005 0.0199999 0.688047 0.542 0.688047 1.19C0.688047 1.784 1.21005 2.306 1.82205 2.306C2.45205 2.306 2.97405 1.802 2.97405 1.172C2.97405 0.524 2.47005 0.0199999 1.82205 0.0199999ZM6.99002 0.0199999C6.37802 0.0199999 5.85602 0.542 5.85602 1.19C5.85602 1.784 6.37802 2.306 6.99002 2.306C7.62002 2.306 8.14202 1.802 8.14202 1.172C8.14202 0.524 7.63802 0.0199999 6.99002 0.0199999ZM12.158 0.0199999C11.546 0.0199999 11.024 0.542 11.024 1.19C11.024 1.784 11.546 2.306 12.158 2.306C12.788 2.306 13.31 1.802 13.31 1.172C13.31 0.524 12.806 0.0199999 12.158 0.0199999Z" fill="#00136F"/>
          </svg>
        </span>
      </li>
    </ul>

    <schedule-modal
      :week-day="selectedWeekDay"
      :periods="selectedPeriods"
      :duration="duration"
      :gap="gap"
      @save="saveModalChanges"
    />
  </div>
</template>

<script>
import { getTime } from '@/utils/schedule.js';
import ScheduleModal from '@/components/modals/ScheduleModal';

export default {
  name: 'Availability',
  components: { ScheduleModal },
  props: ['value', 'duration', 'gap'],
  watch: {
    value: {
      immediate: true,
      handler() {
        this.populateDays();
      }
    }
  },
  data() {
    return {
      days: [
        {
          key: 'monday',
          name: 'Segunda',
          periods: []
        },
        {
          key: 'tuesday',
          name: 'Terça',
          periods: []
        },
        {
          key: 'wednesday',
          name: 'Quarta',
          periods: []
        },
        {
          key: 'thursday',
          name: 'Quinta',
          periods: []
        },
        {
          key: 'friday',
          name: 'Sexta',
          periods: []
        },
        {
          key: 'saturday',
          name: 'Sábado',
          periods: []
        },
        {
          key: 'sunday',
          name: 'Domingo',
          periods: []
        },
      ],
      selectedWeekDay: null,
      selectedPeriods: []
    }
  },
  methods: {
    populateDays() {
      const weekDays = Object.keys(this.value);

      weekDays.forEach((key) => {
        const periods = this.value[key].map((period) => {
          return getTime(period);
        });

        const day = this.days.find((d) => d.key === key);
        day.periods = periods;
      });
    },

    openSchedule(weekday) {
      this.selectedWeekDay = weekday.key;
      this.selectedPeriods = this.value[weekday.key] || [];
      this.$store.dispatch('consultants/openModal');
    },

    saveModalChanges(newPeriod) {
      const newValue = Object.assign(this.value, {
        [this.selectedWeekDay]: newPeriod,
      })

      this.$emit('input', newValue);
      this.populateDays();
    }
  }
}
</script>

<style lang="scss">
  .availability-item {
    min-height: 120px;

    &:nth-child(6),
    &:nth-child(7) {
      opacity: 0.5;
    }
  }
</style>
