<template>
  <div class="flex">
    <label
      class="mr-5 cursor-pointer rounded px-5 py-3 border border-red-200 text-blue text-sm whitespace-nowrap transition duration ease-in-out"
      :class="{
        'bg-red-200': value === option
      }"
      :key="option"
      v-for="option in options"
    >
      <input
        class="hidden"
        type="radio"
        :name="name"
        :value="option"
        @input="onInput(option)"
      />

      <b class="text-lg font-normal">{{ option }}</b> min
    </label>
  </div>
</template>

<script>
export default {
  name: 'Minutes',
  props: ['value', 'name', 'options'],
  methods: {
    onInput(option) {
      this.$emit('input', option);
    }
  }
}
</script>

<style lang="scss">

</style>
