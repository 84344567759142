<template>
  <div class="file-input relative">
    <label class="block">
      <p class="absolute block leading-none text-lg text-blue pt-3">{{ title }}</p>

      <div class="file-input__field relative flex justify-end items-end">
        <span class="flex flex-grow-0 items-center px-10 rounded text-white text-sm bg-blue-100 h-10 mb-2">
          Selecionar arquivo
        </span>
      </div>
      <input class="opacity-0 absolute left-0 top-0 w-full h-full" type="file" @change="setImage" />
    </label>

    <div v-if="instructions" class="text-xs text-blue font-light mt-2">
      {{ instructions }}
    </div>

    <div v-if="value || preview" class="relative w-20 h-20 mt-5">
      <span @click="removeImage" class="absolute top-0 right-0 w-6 h-6 flex justify-center items-center bg-white border rounded-full z-10 cursor-pointer">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.6666 2.27301L9.72659 1.33301L5.99992 5.05967L2.27325 1.33301L1.33325 2.27301L5.05992 5.99967L1.33325 9.72634L2.27325 10.6663L5.99992 6.93967L9.72659 10.6663L10.6666 9.72634L6.93992 5.99967L10.6666 2.27301Z" fill="#00136F" stroke="white" stroke-width="0.5"/>
        </svg>
      </span>

      <img
        class="absolute top-0 left-0 w-full h-full object-contain rounded-full overflow-hidden"
        :src="preview || value"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Phone",
  props: {
    value: {
      type: String
    },
    name: {
      type: String
    },
    title: {
      type: String
    },
    instructions: {
      type: String
    }
  },
  data() {
    return {
      preview: null
    }
  },
  methods: {
    setImage(event) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        this.preview = e.target.result;
      };

      reader.readAsDataURL(file);

      this.$emit('set', event);
    },
    removeImage() {
      this.preview = null;
      this.$emit('remove');
    },
  },
};
</script>

<style lang="scss">
  .file-input__field {
    border-bottom: 1.5px solid rgba(0, 19, 111, 0.25);
  }
</style>
